/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Button, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kuchyně"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1k9d6fy --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/e2502eccdc44417d959b17e218133908_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/e2502eccdc44417d959b17e218133908_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/e2502eccdc44417d959b17e218133908_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/e2502eccdc44417d959b17e218133908_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/e2502eccdc44417d959b17e218133908_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/e2502eccdc44417d959b17e218133908_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/e2502eccdc44417d959b17e218133908_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/e2502eccdc44417d959b17e218133908_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Kuchyně</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"gldri53pug"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":678}}>
              
              <Title className="title-box" style={{"maxWidth":930}} content={"Kuchyně je v dnešní době neoddělitelnou součástí obytného prostoru a centrem rodinného života.&nbsp;"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":649}} content={"Tomu by měla odpovídat kvalita, estetika a ergonomie. Možnosti designu jsou díky hře materiálů a barev téměř nekonečné a společně nám dávají příležitost vytvořit si svou jedinečnou kuchyň."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--80" name={"1d8u6246rn5"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/e81b90f38cc14d6eb04eeb9cee19898b_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/e81b90f38cc14d6eb04eeb9cee19898b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/e81b90f38cc14d6eb04eeb9cee19898b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/e81b90f38cc14d6eb04eeb9cee19898b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/e81b90f38cc14d6eb04eeb9cee19898b_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/c2fe1d19a2e346caae787d227b8a0196_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/c2fe1d19a2e346caae787d227b8a0196_s=350x_.JPG 350w, https://cdn.swbpg.com/t/10513/c2fe1d19a2e346caae787d227b8a0196_s=660x_.JPG 660w, https://cdn.swbpg.com/t/10513/c2fe1d19a2e346caae787d227b8a0196_s=860x_.JPG 860w, https://cdn.swbpg.com/t/10513/c2fe1d19a2e346caae787d227b8a0196_s=1400x_.JPG 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/4f9e103a835843149fc79a21c8f70df3_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/4f9e103a835843149fc79a21c8f70df3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/4f9e103a835843149fc79a21c8f70df3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/4f9e103a835843149fc79a21c8f70df3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/4f9e103a835843149fc79a21c8f70df3_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/74244c59aeab41eb9d4b374837d77cf1_s=860x_.JPG"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/74244c59aeab41eb9d4b374837d77cf1_s=350x_.JPG 350w, https://cdn.swbpg.com/t/10513/74244c59aeab41eb9d4b374837d77cf1_s=660x_.JPG 660w, https://cdn.swbpg.com/t/10513/74244c59aeab41eb9d4b374837d77cf1_s=860x_.JPG 860w, https://cdn.swbpg.com/t/10513/74244c59aeab41eb9d4b374837d77cf1_s=1400x_.JPG 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/2777fdf8ff06487f9a8b3fca1c6b5c68_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/2777fdf8ff06487f9a8b3fca1c6b5c68_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/2777fdf8ff06487f9a8b3fca1c6b5c68_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/2777fdf8ff06487f9a8b3fca1c6b5c68_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/2777fdf8ff06487f9a8b3fca1c6b5c68_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/edf489e1da3c43c4b03d7b218037f30d_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/edf489e1da3c43c4b03d7b218037f30d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/edf489e1da3c43c4b03d7b218037f30d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/edf489e1da3c43c4b03d7b218037f30d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/edf489e1da3c43c4b03d7b218037f30d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10513/edf489e1da3c43c4b03d7b218037f30d_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/9822bf75db9446e0bbe72c43a9a9645a_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/9822bf75db9446e0bbe72c43a9a9645a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/9822bf75db9446e0bbe72c43a9a9645a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/9822bf75db9446e0bbe72c43a9a9645a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/9822bf75db9446e0bbe72c43a9a9645a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10513/9822bf75db9446e0bbe72c43a9a9645a_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/bca279a628844e30bf5cf81e50e92567_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/bca279a628844e30bf5cf81e50e92567_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/bca279a628844e30bf5cf81e50e92567_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/bca279a628844e30bf5cf81e50e92567_s=860x_.jpg 860w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/db2dd5373f134e9caf4978849f4c6a78_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/db2dd5373f134e9caf4978849f4c6a78_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/db2dd5373f134e9caf4978849f4c6a78_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/db2dd5373f134e9caf4978849f4c6a78_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/db2dd5373f134e9caf4978849f4c6a78_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/66403bdbc9a64f38a4328369933ac0a5_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/66403bdbc9a64f38a4328369933ac0a5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/66403bdbc9a64f38a4328369933ac0a5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/66403bdbc9a64f38a4328369933ac0a5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/66403bdbc9a64f38a4328369933ac0a5_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10513/66403bdbc9a64f38a4328369933ac0a5_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/ae3933da1e3346bd82e4ddb1e2b72f30_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/ae3933da1e3346bd82e4ddb1e2b72f30_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/ae3933da1e3346bd82e4ddb1e2b72f30_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/ae3933da1e3346bd82e4ddb1e2b72f30_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/ae3933da1e3346bd82e4ddb1e2b72f30_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10513/6742ad03784643feb9b8f4975706f0f8_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10513/6742ad03784643feb9b8f4975706f0f8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/6742ad03784643feb9b8f4975706f0f8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/6742ad03784643feb9b8f4975706f0f8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/6742ad03784643feb9b8f4975706f0f8_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/8d096265667445a8acbdcb3577f9ae56_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/8d096265667445a8acbdcb3577f9ae56_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/8d096265667445a8acbdcb3577f9ae56_s=660x_.jpg 660w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/ffa8c6b3c1e24c3c957145fe5b6026ca_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/ffa8c6b3c1e24c3c957145fe5b6026ca_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/ffa8c6b3c1e24c3c957145fe5b6026ca_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/ffa8c6b3c1e24c3c957145fe5b6026ca_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/ffa8c6b3c1e24c3c957145fe5b6026ca_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/10513/caaf3f05c3e146de997d41ba46d342f3_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/10513/caaf3f05c3e146de997d41ba46d342f3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/caaf3f05c3e146de997d41ba46d342f3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/caaf3f05c3e146de997d41ba46d342f3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10513/caaf3f05c3e146de997d41ba46d342f3_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-12vzq2n css-eivkvl --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=3000x_.jpg);
    }
  
background-position: 50% 78%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Máte zájem o kuchyň?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--center mt--16" use={"page"} href={"/kontakt"} content={"Kontaktovat&nbsp; ➔"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3 flex--center" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500" content={"<span style=\"color: var(--color-dominant);\">Living 4 Handy</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":224}} content={"<span style=\"color: rgb(220, 221, 221);\">Výrobce kvalitních kuchyní a nábytku na míru pro Vaši spokojenost. Realizujeme  po Praze, Středočeském kraji i okolí.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":473}} content={"<span style=\"color: rgb(248, 248, 248);\">IČ 25731491<br>DIČ CZ25731491<br><br>Společnost je vedená u Městského soudu v Praze, spisová značka C 65152</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500 lh--14" content={"<span style=\"color: var(--color-dominant);\">+420 777 206 285<br>info@living4handy.cz<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: rgb(220, 221, 221);\">Living4Handy s.r.o.\n<br>Luženská 2801<br>&nbsp;Rakovník</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}